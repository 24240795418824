<template>
  <div>
    <b-form-group>
      <b-form-select v-model="farmer" v-on:change="regist" required>
        <option :value="null" disabled>-- 農家を選択してください --</option>
        <option
          v-for="item in farmers"
          :key="item.node.farmer.id"
          :value="item.node.farmer"
        >{{ item.node.farmer.name }}</option>
      </b-form-select>
    </b-form-group>
  </div>
</template>
<script>
import { MY_FARMER_QUERY } from "../../../components/current-farmer-query";
import { FARMER_ID } from "../../../components/auth-settings";
export default {
  props: {
    propFarmer: null
  },
  data() {
    return {
      farmer: null,
      farmers: []
    };
  },
  watch: {
    propFarmer: function() {
      this.farmer = this.propFarmer;
    }
  },
  beforeCreate() {
    this.$apollo
      .query({
        query: MY_FARMER_QUERY
      })
      .then(result => {
        this.farmers = result.data.me.farmersoperatorsSet.edges;
      });
  },
  methods: {
    regist: function() {
      localStorage.setItem(FARMER_ID, this.farmer.id);
      this.$emit("changeFarmer", this.farmer);
    }
  }
};
</script>
