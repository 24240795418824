<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit.prevent="login">
                  <h2>農家選択</h2>
                  <p class="text-muted">ログインする農家を選択してください</p>
                  <my-farmer-form />
                  <b-row>
                    <b-col>
                      <b-button type="submit" size="sm" variant="primary">ログイン</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import MyFarmerForm from "../components/form/MyFarmerForm";
export default {
  components: {
    MyFarmerForm
  },
  data() {
    return {};
  },
  methods: {
    login: function() {
      this.$router.push({ path: "/dashboard" });
    }
  }
};
</script>
